import React from 'react';
import styled from 'styled-components';
import Carousel2 from './Carousel2'; // Ensure the path to Carousel is correct
import Medium from './Assets/gifs/Medium.gif'; // Example path to a downloaded GIF
import Small from './Assets/gifs/Small.gif';
import Big from './Assets/gifs/Big.gif';

const PricingContainer = styled.section`
  height: auto;  // Adjusted to auto to contain children properly
  background-color: #FEFFF8;
  color: #333;
  padding: 10px;
  padding-top: 20px;
  font-size: 20px;
`;

const Heading = styled.div`
  h1 {
    margin-top: 10px;  // Add margin to the top of headings
    margin-bottom: 30px;  // Add margin to the bottom of headings
    width: 100%;  // Ensures headings do not exceed the caption width
    
  },  h4 {
    margin-top: 0px;  // Add margin to the top of headings
    margin-bottom: 10px;  // Add margin to the bottom of headings
    width: 100%;  // Ensures headings do not exceed the caption width
    
  }
`;



const Pricing = () => {
  const imagesInfo = [
    
    
    { src: Small, caption: <div><h4>Küçük Boy Depo</h4><h4>3,12&#13221; (Y 100cm - D 200cm - G 156cm)</h4><h4>Aylık Depolama Bedeli : 990 TL + KDV</h4><h4>Tek Yön Nakliye Bedeli : 3000 TL + KDV</h4></div>},
    { src: Medium, caption: <div><h4>Orta Boy Depo</h4><h4>6,24&#13221; (Y 200cm - D 200cm - G 156cm)</h4><h4>Aylık Depolama Bedeli : 1800 TL + KDV</h4><h4>Tek Yön Nakliye Bedeli : 4000 TL + KDV</h4></div>},
    { src: Big, caption: <div><h4>Büyük Boy Depo</h4><h4>6,24&#13221; (Y 230cm - D 200cm - G 156cm)</h4><h4>Aylık Depolama Bedeli : 1990 TL + KDV</h4><h4>Tek Yön Nakliye Bedeli : 4500 TL + KDV</h4></div>},
  ];

  return (
    <PricingContainer id="pricing">
      <Heading>
        <h1>Depo Çeşitleri ve Fiyatları (2024)</h1>
        <p>&emsp;Eşyalarınız için gerekli olan depolama alanını öğrenmek ve fiyat teklifi almak için hemen arayın.</p>
      </Heading>
      <Carousel2 images={imagesInfo} /> {/* Include the Carousel component here */}
      <ul>
      <li>Fiyatlarımız İstanbul için geçerlidir.</li>
      <li>Depolama ödemelerini kredi kartı ile yapabilirsiniz.</li>
      </ul>
    </PricingContainer>
  );
};

export default Pricing;
