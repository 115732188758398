import React from 'react';
import styled from 'styled-components';

const ContactContainer = styled.section`
  display: flex;
  flex-direction: column;
  background-color: #B72121;
  color: white;
  padding: 10px;
  padding-top: 20px;
  font-size: 20px;
  text-align: center;

  @media (min-width: 768px) {
    flex-direction: row;
    height: 80vh;
  }
`;

const Column = styled.div`
  flex: 1;
  padding: 20px;
`;

const Map = styled.iframe`
  width: 100%;
  height: 300px;  // Adjust this value for desired height
  border: 0;

  @media (min-width: 768px) {
    height: 100%;
  }
`;

const Contact = () => {
  return (
    <div id="contact">
      <ContactContainer>
        <Column>
          <h4>Memnun Depo Nakliyat Lojistik San ve Tic Ltd. Şti.</h4>
          <p>Zafer Mah. 118.Sok No.10/2 34197 Esenyurt İstanbul</p><br/>
          <h4>İletişim</h4>
          <p>info@memnundepo.com</p>
          <p>Telefon: 212 758 42 21</p><br/>
          <h4>Çalışma Saatleri</h4>
          <p>Hafta içi: 08:00 - 17:00</p>
          <p>Cumartesi: 09:00 - 13:00</p>
          <p>Pazar: Kapalı</p>
        </Column>
        <Column>
          <Map 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3010.1059431838985!2d28.681429375427687!3d41.0229380713485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa1fdf24a3f89%3A0x5075c209526a579f!2sMemnun%20Depo!5e0!3m2!1sen!2str!4v1719478203321!5m2!1sen!2str"
            allowFullScreen="" 
            loading="lazy"
          ></Map>
        </Column>
      </ContactContainer>
    </div>
  );
};

export default Contact;
