import React, { useEffect } from 'react';
import styled from 'styled-components';
import heroImage from './Assets/ic.jpg'; // Import the hero section background image
import img1 from './Assets/1.jpeg';
import img2 from './Assets/2.jpeg';
import img3 from './Assets/3.jpeg';
import img4 from './Assets/4.jpeg';
import img5 from './Assets/5.jpeg';
import img6 from './Assets/6.jpeg';
import img7 from './Assets/7.jpg';
import img8 from './Assets/8.jpg';
import img9 from './Assets/9.jpg';
import img10 from './Assets/10.jpg';
import img11 from './Assets/11.jpg';

import Footer from './Footer'; // Import Footer
import Contact from './Contact';
import Carousel3 from './Carousel3'; // Import the Carousel3 component

const Section = styled.section`
  padding: 40px 20px;
  text-align: center;
  background: ${({ bg }) => bg || '#fff'};
`;

const SectionSmall = styled.section`
  padding: 20px 20px;
  text-align: center;
  background: ${({ bg }) => bg || '#fff'};
`;

const HeroSection = styled(Section)`
  position: relative;
  color: #f22ff;
  overflow: hidden;
`;

const HeroImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(${heroImage}) center/cover no-repeat;
  opacity: 0.3; /* Adjust this value to control the transparency */
  z-index: 0;
`;

const HeroContent = styled.div`
  position: relative;
  z-index: 1;
`;


const shuffleArray = (array) => {
  let shuffledArray = array.slice();
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

const Kurumsal = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const images = [
    { src: img1 },
    { src: img2 },
    { src: img3 },
    { src: img4 },
    { src: img5 },
    { src: img6 },
    { src: img7 },
    { src: img8 },
    { src: img9 },
    { src: img10 },
    { src: img11 },
  ];

  return (
    <div>
      <HeroSection>
        <HeroImage />
        <HeroContent>
          <h1>Lojistik ve Sanayi İçin İdeal Depolama Alanları</h1>
          <h3>
            Memnun Depo olarak lojistik giriş çıkışlarına uygun, geniş alanlarımızla hizmetinizdeyiz.
            Paletli malzemeler, endüstriyel makineler, hammaddeler gibi yüksek ağırlığa sahip ürünlerinizi
            güvenle depolayabilirsiniz. Sanayi elektriğine sahip altyapımız sayesinde,
            depomuzda endüstriyel makinelerinizi deneyebilir ve sergileyebilirsiniz.
            İhtiyaçlarınıza uygun esnek çözümler sunan depomuzla iş süreçlerinizi kolaylaştırıyoruz.
          </h3>
        </HeroContent>
      </HeroSection>
      <SectionSmall bg="#FFF">
        <Carousel3 images={shuffleArray(images)} />
      </SectionSmall>
      <Section bg="#f7f7f7">
        <h2>Kullanım Alanı</h2>
        <p>
          • Toplam 2000m² kapalı alan<br/>
          • 1200m² tonajlı depolama alanı (2 Ton/m²)<br/>
          • 800m² standart depolama alanı<br/>
          • 7m tavan yüksekliği
        </p>
      </Section>
      <Section>
        <h2>Lojistik</h2>
        <p>
          • Depo girişi tırların yanaşması ve indir bindir işlemleri için uygun.<br/>
          • 40 Ayak kamyon’a kadar büyüklüklerdeki araçların depoya tamamen girip yük indirebilmesi imkanı.<br/>
          • 1 adet forklift ( h 4.6m erişim, 2 ton taşıma kapasitesi )<br/>
          • 1 adet 10 tonluk köprülü vinç<br/>
          • 1 adet 5 tonluk köprülü vinç<br/>
          • 1 adet 3 tonluk asma vinç<br/>
          • Forklift ile araçtan alınamayacak malzemeler için vinçler ile indir-bindir imkanı
        </p>
      </Section>
      <SectionSmall bg="#FFF">
        <Carousel3 images={shuffleArray(images)} />
      </SectionSmall>
      <Section bg="#f7f7f7">
        <h2>Güvenlik</h2>
        <p>
          • 7/24 Güvenlik Personeli<br/>
          • Pronet Alarm Sistemi<br/>
          • Kameralar<br/>
          • Kapılarda manyetik sensörler.<br/>
          • Mücbir sebepler için uygun sigorta imkanı
        </p>
      </Section>
      <Section>
        <h2>Saklama Koşulları</h2>
        <p>
          • Robur fanlar sayesinde air conditioning.<br/>
          • Nem ve sıcaklık sensörleri ile rutubet küf gibi sorunların kontrolü.
        </p>
      </Section>
      <Section bg="#f7f7f7">
        <h2>Fiyat Listesi</h2>
        <h4>Uzun Dönem Kiralama ( en az 1 ay )</h4>
        <p>
          • M² başına 1.000TL/AY<br/>
          • M³ başına 500TL/AY<br/><br/>
        </p>
        <h4>Kısa Dönem Kiralama ( Günlük gir çık dahil )</h4>
        <p>
          • Europalet 60 TL/Gün<br/>
          • Aylık palet abonelik %20 indirim
        </p>
      </Section>
      <SectionSmall bg="#FFF">
        <Carousel3 images={shuffleArray(images)} />
      </SectionSmall>
      <Section>
        <h2>Ulaşım</h2>
        <p>
          • Tem’e uzaklık 4km<br/>
          • E5’e uzaklık 2km<br/>
          • Ambarlı Limanı’na uzaklık 6km<br/>
          • Google veya Yandexte “Memnun Depo” yu aratarak yönlendirme alabilirsiniz.<br/>
        </p>
      </Section>
      <Contact />
      <Footer />
    </div>
  );
};

export default Kurumsal;
