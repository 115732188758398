import React from 'react';
import styled from 'styled-components';
import Carousel from './Carousel'; // Ensure the path to Carousel is correct
import image1 from './Assets/image1.png'; // Adjust path as necessary
import image2 from './Assets/image2.png'; // Adjust path as necessary
import image3 from './Assets/NA3.png'; // Adjust path as necessary
import image4 from './Assets/image4.png'; // Adjust path as necessary

const ServicesContainer = styled.section`
  height: auto; // Changed from 100vh to auto to accommodate varying content sizes
  background-color: #FEFFF8;
  color: #333;
  padding: 10px;
  padding-top: 0px;
  font-size: 20px;
`;

const Services = () => {
  // Array of imported images
  const imagesInfo = [
    { src: image1, caption: "1. Deponuz kapınıza getirilir." },
    { src: image2, caption: "2. Eşyalarınız ekibimiz tarafından paketlenir." },
    { src: image3, caption: "3. Kutunuza yerleştirilir ve kutunuz kilitlenir." },
    { src: image4, caption: "4. Depomuzda saklanır." }
  ];

  return (
    <ServicesContainer id="services">
      <h1>Eşyalarınızı Depoluyoruz!</h1>
      <p> &emsp;   Eşyalarınız özenle paketlenip size özel deponuza yerleştirilir,
         deponuz kilitlenir ve tek anahtarı size teslim edilir. Her kişisel depo eşyalarınızın güvenliği için
        tasarlanmıştır.</p>
      <Carousel images={imagesInfo} /> {/* Include the Carousel component here */}
      <p>&emsp; Farklı ebatlar arasından size uygun boyuttaki deponuzu seçmeniz yeterli!<br/>&emsp; Uzman ekibimiz eşyalarınızı söker,paketler, saklanmak üzere deponuza yerleştirir.</p>
      
    </ServicesContainer>
  );
};

export default Services;

