import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';
import { NavLink as RouterNavLink, useLocation, useNavigate } from 'react-router-dom';
import Logo from '../swgs/MemnunDepo.webp'; // Import your logo here
import CallIcon from '../swgs/callIcon.webp'; // Import your call icon here

const Nav = styled.nav`
  background: #B72121;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  position: sticky;
  top: 0;
  z-index: 1000;
  @media screen and (max-width: 768px) {
    height: 80px;
  }
`;

const Hamburger = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    display: flex;
    position: absolute;
    top: 32px;
    right: 20px;
    z-index: 2000;
  }
  span {
    height: 2px;
    width: 25px;
    background: #FFFFF0;
    margin-bottom: 4px;
    transition: all 0.3s ease-in-out;
  }
`;

const LogoImg = styled.img`
  height: 84px;
  width: auto;
  cursor: pointer;
`;

const NavMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    height: 50%;
    position: fixed;
    top: 0;
    right: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
    opacity: 0.9;
    transition: all 0.3s ease;
    background: #B72121;
    padding-top: 100px;
    z-index: -1;
  }
`;

const NavLink = styled(Link)`
  color: #FEFFF8;
  padding: 8px 16px;
  margin: 0 5px;
  cursor: pointer;
  font-weight: 700;
  text-decoration: none;
  font-family: "Lato", sans-serif;
  background-color: #C84C4B;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
  &:hover {
    background-color: #56C596;
    color: #FFFFF0;
  }
  &.active, &.highlight {
    background-color: #FFFFF0;
    color: #333;
  }
  @media screen and (max-width: 768px) {
    display: block;
    width: 100%;
    text-align: center;
    margin: 5px 0;
  }
`;

const ExternalNavLink = styled(RouterNavLink)`
  color: #FEFFF8;
  padding: 8px 16px;
  margin: 0 5px;
  cursor: pointer;
  font-weight: 700;
  text-decoration: none;
  font-family: "Lato", sans-serif;
  background-color: #C84C4B;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
  &:hover {
    background-color: #56C596;
    color: #FFFFF0;
  }
  &.active, &.highlight {
    background-color: #FFFFF0;
    color: #333;
  }
  @media screen and (max-width: 768px) {
    display: block;
    width: 100%;
    text-align: center;
    margin: 5px 0;
  }
`;

const CallAction = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #FFFFF0;
  font-size: 16px;
  position: flex;
  right: 20px;
  @media screen and (max-width: 768px) {
    position: flex;
    left: 60%;
    transform: translateX(-10%);
    top: 20px;
    margin-right: 30px;
  }
`;

const CallIconImg = styled.img`
  height: 40px;
  width: auto;
  margin-right: 5px;
  margin-top: 5px;
`;

const PhoneNumber = styled.a`
  color: #FFFFF0;
  text-decoration: none;
  font-size: 20px;
  &:hover {
    text-decoration: underline;
  }
`;

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const toggle = () => setIsOpen(!isOpen);
  const closeMenu = () => setIsOpen(false);

  const handleLogoClick = () => {
    navigate('/');
    scrollToTop();
  };

  const scrollToTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
      window.requestAnimationFrame(scrollToTop);
      window.scrollTo(0, c - c / 8);
    }
  };

  const handleNavLinkClick = (section) => {
    if (location.pathname !== '/') {
      navigate('/');
      setTimeout(() => {
        const sectionElement = document.getElementById(section);
        if (sectionElement) {
          window.scrollTo({
            top: sectionElement.offsetTop - 100, // Adjust this value for exact position
            behavior: 'smooth'
          });
        }
      }, 500); // Increased delay to 500ms for better effect
    } else {
      const sectionElement = document.getElementById(section);
      if (sectionElement) {
        window.scrollTo({
          top: sectionElement.offsetTop - 100, // Adjust this value for exact position
          behavior: 'smooth'
        });
      }
    }
    closeMenu();
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest('#navbar')) {
        closeMenu();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, []);

  return (
    <Nav id="navbar">
      <LogoImg src={Logo} alt="Memnun Depo Logo" onClick={handleLogoClick} />
      <NavMenu isOpen={isOpen}>
        <NavLink to="services" spy={true} smooth={true} offset={-100} duration={500} onClick={() => handleNavLinkClick('services')}>
          HIZMETLER
        </NavLink>
        <NavLink to="pricing" spy={true} smooth={true} offset={-70} duration={500} onClick={() => handleNavLinkClick('pricing')}>
          FIYATLAR
        </NavLink>
        <NavLink to="contact" spy={true} smooth={true} offset={-70} duration={500} onClick={() => handleNavLinkClick('contact')}>
          ILETISIM
        </NavLink>
        <ExternalNavLink to="/kurumsal" onClick={closeMenu}>
          KURUMSAL
        </ExternalNavLink>
      </NavMenu>
      <Hamburger onClick={toggle}>
        <span></span>
        <span></span>
        <span></span>
      </Hamburger>
      <CallAction>
        <a href="tel:212 758 42 14" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}>
          <CallIconImg src={CallIcon} alt="Call Icon" />
          <PhoneNumber><strong>212 758 42 14</strong></PhoneNumber>
        </a>
      </CallAction>
    </Nav>
  );
};

export default Navbar;
