// components/Footer.js
import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background: #B72121; // Deep Red
  color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px 0px;
  position: fixed; // Changed from sticky to fixed
  bottom: 0;
  width: 100%;
  z-index: 1000; // Ensure it stays above other content
`;

const FooterLink = styled.a`
  text-decoration: none; // Removes underline from links
`;

const FooterButton = styled.button`
  background: #56C596; // Lighter Red #C84C4B
  color: #FEFFF8;
  border: none; // Removed border for cleaner look
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
  font-size: 16px;

  &:hover {
    background-color: #C84C4B; // Green for hover
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterLink href="tel:+902127584214"> {/* Updated href with phone number */}
        <FooterButton>Hemen Ara</FooterButton>
      </FooterLink>
    </FooterContainer>
  );
};

export default Footer;
