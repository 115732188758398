import React from 'react';
import styled from 'styled-components';

const ArrowButton = styled.button`
  background: rgba(86, 197, 150, 0.4);  // Semi-transparent background
  border: 2px solid #fff;
  border-radius: 50%;
  color: #fff;  // White arrow color
  cursor: pointer;
  position: absolute;
  top: 50%;
  z-index: 1;
  width: 45px;  // Maintain the button size
  height: 45px;  // Maintain the button size
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);
  font-size: 24px;  // Increased font size for larger arrows

  &:hover {
    color: #ccc;  // Change color on hover
  }
`;

const PrevArrow = ({ className, style, onClick }) => (
  <ArrowButton
    className={className}
    style={{ ...style, left: 10 }}  // Adjust left position as needed
    onClick={onClick}
  >
  </ArrowButton>
);

const NextArrow = ({ className, style, onClick }) => (
  <ArrowButton
    className={className}
    style={{ ...style, right: 10 }}  // Adjust right position as needed
    onClick={onClick}
  >
  </ArrowButton>
);
export { PrevArrow, NextArrow };
