import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PrevArrow, NextArrow } from './arrows';

const CarouselContainer = styled.div`
  width: 97%;
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 18px;
  outline: 1px solid #B72121;
`;

const StyledImageContainer = styled.div`
  width: 99%;
  padding-top: 100%;
  position: relative;
  overflow: hidden; 
`;

const StyledImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const SlideContainer = styled.div`
  position: relative;
  width: 80%;
  text-align: center;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Carousel3 = ({ images }) => {
  const [key, setKey] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setKey(prevKey => prevKey + 1);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    cssEase: "ease-out",
    adaptiveHeight: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          autoplay: false,
          speed: 300,
          autoplaySpeed: 6000,
          cssEase: "ease-out",
          adaptiveHeight: true
        }
      },
      {
        breakpoint: 568,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          autoplay: true,
          speed: 300,
          autoplaySpeed: 6000,
          cssEase: "ease-out",
          adaptiveHeight: true
        }
      }
    ]
  };

  return (
    <CarouselContainer key={key}>
      <Slider {...settings}>
        {images.map((item, index) => (
          <SlideContainer key={index}>
            <StyledImageContainer>
              <StyledImage src={item.src} alt={`Slide ${index + 1}`} />
            </StyledImageContainer>
          </SlideContainer>
        ))}
      </Slider>
    </CarouselContainer>
  );
};

export default Carousel3;
