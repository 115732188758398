import React from 'react';
import Navbar from './components/Navbar';
import Services from './components/Services';
import Pricing from './components/Pricing';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Kurumsal from './components/Kurumsal';
import GlobalStyle from './GlobalStyles';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const App = () => {
  return (
    <Router>
      <div className="App">
        <GlobalStyle />
        <Navbar />
        <Routes>
          <Route path="/" element={
            <>
              <Services />
              <Pricing />
              <Contact />
            </>
          } />
          <Route path="/kurumsal" element={<Kurumsal />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
