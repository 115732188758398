import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PrevArrow, NextArrow } from './arrows';

const CarouselContainer = styled.div`
  width: 97%;
  padding: 5px;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 8px;
  outline: 2px solid #B72121;
`;

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  margin: 10px;
  max-width: calc(100% - 20px);

  @media screen and (min-width: 769px) and (max-width: 1224px) {
    max-height: 350px;
    margin: 15px;
    max-width: calc(100% - 30px);
  }

  @media screen and (max-width: 768px) {
    max-height: 350px;
    margin: 2px;
    max-width: calc(100% - 5px);
  }
`;

const ImageCaption = styled.div`
  background-color: #c46968;
  color: white;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 5px;
  padding-right: 13px;
  font-size: ${({ fontSize }) => fontSize || '16px'};
  width: calc(100% - 20px);
  min-height: 70px;
  border-radius: 8px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: top;
`;

const SlideContainer = styled.div`
  position: relative;
  width: 80%;
  text-align: center;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Carousel = ({ images }) => {
  const [key, setKey] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setKey(prevKey => prevKey + 1);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "ease-out",
    adaptiveHeight: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1224,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          autoplay: true,
          speed: 300,
          autoplaySpeed: 3000,
          cssEase: "ease-out",
          adaptiveHeight: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          autoplay: true,
          speed: 300,
          autoplaySpeed: 3000,
          cssEase: "ease-out",
          adaptiveHeight: true
        }
      }
    ]
  };

  return (
    <CarouselContainer key={key}>
      <Slider {...settings}>
        {images.map((img, index) => (
          <SlideContainer key={index}>
            <StyledImage src={img.src} alt={`Slide ${index + 1}`} />
            <ImageCaption fontSize="18px">{img.caption}</ImageCaption>
          </SlideContainer>
        ))}
      </Slider>
    </CarouselContainer>
  );
};

export default Carousel;
